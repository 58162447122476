export default [
  {
    value: "cameras",
    text: "Cameras",
    width: 250,
    sortable: true,
    filterable: true,
    toStringFn: (item, key) => item[key].map((camera) => camera.name),
  },
  {
    value: "projects",
    text: "Projects",
    width: 250,
    sortable: true,
    filterable: true,
    toStringFn: (item, key) => item[key].map((project) => project.name),
  },
  {
    value: "provider",
    text: "Destination",
    width: 120,
    sortable: true,
    align: "center",
    filterable: true,
  },

  {
    value: "config",
    text: "Recipients",
    width: 150,
    sortable: true,
    align: "center",
    filterable: true,
  },

  {
    value: "notifyDays",
    text: "Notify Days",
    width: 150,
    sortable: true,
    align: "center",
    filterable: true,
  },
  {
    value: "notifyTime",
    width: 70,
    text: "Time",
    sortable: true,
    filterable: true,
    align: "center",
  },
  {
    value: "timezone",
    text: "Timezone",
    align: "center",
    width: 130,
    sortable: true,
    filterable: true,
  },
  {
    value: "isPaused",
    text: "Status",
    align: "center",
    width: 70,
    sortable: true,
    filterable: true,
  },
  {
    text: "Actions",
    value: "actions",
    align: "center",
    sortable: false,
    width: 130,
  },
]
