export default [
  { name: "(GMT-12:00) International Date Line West", value: "Etc/GMT+12" },
  { name: "(GMT-11:00) American Samoa", value: "Pacific/Pago_Pago" },
  { name: "(GMT-11:00) Midway Island", value: "Pacific/Midway" },
  { name: "(GMT-10:00) Hawaii", value: "Pacific/Honolulu" },
  { name: "(GMT-09:00) Alaska", value: "America/Juneau" },
  {
    name: "(GMT-08:00) Pacific Time (US & Canada)",
    value: "America/Los_Angeles",
  },
  { name: "(GMT-08:00) Tijuana", value: "America/Tijuana" },
  { name: "(GMT-07:00) Arizona", value: "America/Phoenix" },
  { name: "(GMT-07:00) Chihuahua", value: "America/Chihuahua" },
  { name: "(GMT-07:00) Mazatlan", value: "America/Mazatlany" },
  {
    name: "(GMT-07:00) Mountain Time (US & Canada)",
    value: "America/Denver",
  },
  { name: "(GMT-06:00) Central America", value: "America/Guatemala" },
  {
    name: "(GMT-06:00) Central Time (US & Canada)",
    value: "America/Chicago",
  },
  { name: "(GMT-06:00) Guadalajara", value: "America/Mexico_City" },
  { name: "(GMT-06:00) Mexico City", value: "America/Mexico_City" },
  { name: "(GMT-06:00) Monterrey", value: "America/Monterrey" },
  { name: "(GMT-06:00) Saskatchewan", value: "America/Regina" },
  { name: "(GMT-05:00) Bogota", value: "America/Bogota" },
  {
    name: "(GMT-05:00) Eastern Time (US & Canada)",
    value: "America/New_York",
  },
  {
    name: "(GMT-05:00) Indiana (East)",
    value: "America/Indiana/Indianapolis",
  },
  { name: "(GMT-05:00) Lima", value: "America/Lima" },
  { name: "(GMT-05:00) Quito", value: "America/Lima" },
  { name: "(GMT-04:00) Atlantic Time (Canada)", value: "America/Halifax" },
  { name: "(GMT-04:00) Caracas", value: "America/Caracas" },
  { name: "(GMT-04:00) Georgetown", value: "America/Guyana" },
  { name: "(GMT-04:00) La Paz", value: "America/La_Paz" },
  { name: "(GMT-04:00) Puerto Rico", value: "America/Puerto_Rico" },
  { name: "(GMT-04:00) Santiago", value: "America/Santiago" },
  { name: "(GMT-03:00) Brasilia", value: "America/Sao_Paulo" },
  {
    name: "(GMT-03:00) Buenos Aires",
    value: "America/Argentina/Buenos_Aires",
  },
  { name: "(GMT-03:00) Greenland", value: "America/Godthab" },
  { name: "(GMT-03:00) Montevideo", value: "America/Montevideo" },
  { name: "(GMT-02:00) Mid-Atlantic", value: "Atlantic/South_Georgia" },
  { name: "(GMT-01:00) Azores", value: "Atlantic/Azores" },
  { name: "(GMT-01:00) Cape Verde Is.", value: "Atlantic/Cape_Verde" },
  { name: "(GMT+00:00) Edinburgh", value: "Europe/London" },
  { name: "(GMT+00:00) Belfast", value: "Europe/Belfast" },
  { name: "(GMT+00:00) Guernsey", value: "Europe/Guernsey" },
  { name: "(GMT+00:00) Isle of Man", value: "Europe/Isle_of_Man" },
  { name: "(GMT+00:00) Jersey", value: "Europe/Jersey" },
  { name: "(GMT+00:00) Lisbon", value: "Europe/Lisbon" },
  { name: "(GMT+00:00) London", value: "Europe/London" },
  { name: "(GMT+00:00) Monrovia", value: "Africa/Monrovia" },
  { name: "(GMT+00:00) UTC", value: "Etc/UTC" },
  { name: "(GMT+01:00) Amsterdam", value: "Europe/Amsterdam" },
  { name: "(GMT+01:00) Andorra", value: "Europe/Andorra" },
  { name: "(GMT+01:00) Busingen", value: "Europe/Busingen" },
  { name: "(GMT+01:00) Belgrade", value: "Europe/Belgrade" },
  { name: "(GMT+01:00) Berlin", value: "Europe/Berlin" },
  { name: "(GMT+01:00) Bratislava", value: "Europe/Bratislava" },
  { name: "(GMT+01:00) Brussels", value: "Europe/Brussels" },
  { name: "(GMT+01:00) Budapest", value: "Europe/Budapest" },
  { name: "(GMT+01:00) Casablanca", value: "Africa/Casablanca" },
  { name: "(GMT+01:00) Copenhagen", value: "Europe/Copenhagen" },
  { name: "(GMT+01:00) Gibraltar", value: "Europe/Gibraltar" },
  { name: "(GMT+01:00) Dublin", value: "Europe/Dublin" },
  { name: "(GMT+01:00) Ljubljana", value: "Europe/Ljubljana" },
  { name: "(GMT+01:00) Luxembourg", value: "Europe/Luxembourg" },
  { name: "(GMT+01:00) Madrid", value: "Europe/Madrid" },
  { name: "(GMT+01:00) Malta", value: "Europe/Malta" },
  { name: "(GMT+01:00) Monaco", value: "Europe/Monaco" },
  { name: "(GMT+01:00) Paris", value: "Europe/Paris" },
  { name: "(GMT+01:00) Prague", value: "Europe/Prague" },
  { name: "(GMT+01:00) Rome", value: "Europe/Rome" },
  { name: "(GMT+01:00) Sarajevo", value: "Europe/Sarajevo" },
  { name: "(GMT+01:00) San Marino", value: "Europe/San_Marino" },
  { name: "(GMT+01:00) Skopje", value: "Europe/Skopje" },
  { name: "(GMT+01:00) Stockholm", value: "Europe/Stockholm" },
  { name: "(GMT+01:00) Tirane", value: "Europe/Tirane" },
  { name: "(GMT+01:00) Vienna", value: "Europe/Vienna" },
  { name: "(GMT+01:00) Vatican", value: "Europe/Vatican" },
  { name: "(GMT+01:00) Vaduz", value: "Europe/Vaduz" },
  { name: "(GMT+01:00) Warsaw", value: "Europe/Warsaw" },
  { name: "(GMT+01:00) West Central Africa", value: "Africa/Algiers" },
  { name: "(GMT+01:00) Zagreb", value: "Europe/Zagreb" },
  { name: "(GMT+01:00) Zurich", value: "Europe/Zurich" },
  { name: "(GMT+02:00) Athens", value: "Europe/Athens" },
  { name: "(GMT+02:00) Bucharest", value: "Europe/Bucharest" },
  { name: "(GMT+02:00) Chisinau", value: "Europe/Chisinau" },
  { name: "(GMT+02:00) Cairo", value: "Africa/Cairo" },
  { name: "(GMT+02:00) Harare", value: "Africa/Harare" },
  { name: "(GMT+02:00) Helsinki", value: "Europe/Helsinki" },
  { name: "(GMT+02:00) Jerusalem", value: "Asia/Jerusalem" },
  { name: "(GMT+02:00) Kaliningrad", value: "Europe/Kaliningrad" },
  { name: "(GMT+02:00) Kiev", value: "Europe/Kiev" },
  { name: "(GMT+02:00) Kyiv", value: "Europe/Kyiv" },
  { name: "(GMT+02:00) Mariehamn", value: "Europe/Mariehamn" },
  { name: "(GMT+02:00) Nicosia", value: "Europe/Nicosia" },
  { name: "(GMT+02:00) Oslo", value: "Europe/Oslo" },
  { name: "(GMT+02:00) Podgorica", value: "Europe/Podgorica" },
  { name: "(GMT+02:00) Pretoria", value: "Africa/Johannesburg" },
  { name: "(GMT+02:00) Riga", value: "Europe/Riga" },
  { name: "(GMT+02:00) Sofia", value: "Europe/Sofia" },
  { name: "(GMT+02:00) Tallinn", value: "Europe/Tallinn" },
  { name: "(GMT+02:00) Tiraspol", value: "Europe/Tiraspol" },
  { name: "(GMT+02:00) Uzhgorod", value: "Europe/Uzhgorod" },
  { name: "(GMT+02:00) Vilnius", value: "Europe/Vilnius" },
  { name: "(GMT+02:00) Zaporozhye", value: "Europe/Zaporozhye" },
  { name: "(GMT+03:00) Baghdad", value: "Asia/Baghdad" },
  { name: "(GMT+03:00) Istanbul", value: "Europe/Istanbul" },
  { name: "(GMT+03:00) Kuwait", value: "Asia/Kuwait" },
  { name: "(GMT+03:00) Kirov", value: "Europe/Kirov" },
  { name: "(GMT+03:00) Minsk", value: "Europe/Minsk" },
  { name: "(GMT+03:00) Moscow", value: "Europe/Moscow" },
  { name: "(GMT+03:00) Nairobi", value: "Africa/Nairobi" },
  { name: "(GMT+03:00) Riyadh", value: "Asia/Riyadh" },
  { name: "(GMT+03:00) Simferopol", value: "Asia/Simferopol" },
  { name: "(GMT+04:00) Abu Dhabi", value: "Asia/Muscat" },
  { name: "(GMT+04:00) Baku", value: "Asia/Baku" },
  { name: "(GMT+04:00) Muscat", value: "Asia/Muscat" },
  { name: "(GMT+04:00) Samara", value: "Europe/Samara" },
  { name: "(GMT+04:00) Saratov", value: "Europe/Saratov" },
  { name: "(GMT+04:00) Tbilisi", value: "Asia/Tbilisi" },
  { name: "(GMT+04:00) Ulyanovsk", value: "Europe/Ulyanovsk" },
  { name: "(GMT+04:00) Volgograd", value: "Europe/Volgograd" },
  { name: "(GMT+04:00) Astrakhan", value: "Europe/Astrakhan" },
  { name: "(GMT+04:00) Yerevan", value: "Asia/Yerevan" },
  { name: "(GMT+05:00) Ekaterinburg", value: "Asia/Yekaterinburg" },
  { name: "(GMT+05:00) Islamabad", value: "Asia/Karachi" },
  { name: "(GMT+05:00) Karachi", value: "Asia/Karachi" },
  { name: "(GMT+05:00) Tashkent", value: "Asia/Tashkent" },
  { name: "(GMT+06:00) Almaty", value: "Asia/Almaty" },
  { name: "(GMT+06:00) Astana", value: "Asia/Dhaka" },
  { name: "(GMT+06:00) Dhaka", value: "Asia/Dhaka" },
  { name: "(GMT+06:00) Urumqi", value: "Asia/Urumqi" },
  { name: "(GMT+07:00) Bangkok", value: "Asia/Bangkok" },
  { name: "(GMT+07:00) Hanoi", value: "Asia/Bangkok" },
  { name: "(GMT+07:00) Jakarta", value: "Asia/Jakarta" },
  { name: "(GMT+07:00) Krasnoyarsk", value: "Asia/Krasnoyarsk" },
  { name: "(GMT+07:00) Novosibirsk", value: "Asia/Novosibirsk" },
  { name: "(GMT+08:00) Beijing", value: "Asia/Shanghai" },
  { name: "(GMT+08:00) Chongqing", value: "Asia/Chongqing" },
  { name: "(GMT+08:00) Hong Kong", value: "Asia/Hong_Kong" },
  { name: "(GMT+08:00) Irkutsk", value: "Asia/Irkutsk" },
  { name: "(GMT+08:00) Kuala Lumpur", value: "Asia/Kuala_Lumpur" },
  { name: "(GMT+08:00) Manila", value: "Asia/Manila" },
  { name: "(GMT+08:00) Perth", value: "Australia/Perth" },
  { name: "(GMT+08:00) Singapore", value: "Asia/Singapore" },
  { name: "(GMT+08:00) Taipei", value: "Asia/Taipei" },
  { name: "(GMT+08:00) Ulaanbaatar", value: "Asia/Ulaanbaatar" },
  { name: "(GMT+09:00) Osaka", value: "Asia/Tokyo" },
  { name: "(GMT+09:00) Sapporo", value: "Asia/Tokyo" },
  { name: "(GMT+09:00) Seoul", value: "Asia/Seoul" },
  { name: "(GMT+09:00) Tokyo", value: "Asia/Tokyo" },
  { name: "(GMT+09:00) Yakutsk", value: "Asia/Yakutsk" },
  { name: "(GMT+10:00) Brisbane", value: "Australia/Brisbane" },
  { name: "(GMT+10:00) Canberra", value: "Australia/Canberra" },
  { name: "(GMT+10:00) Guam", value: "Pacific/Guam" },
  { name: "(GMT+10:00) Hobart", value: "Australia/Hobart" },
  { name: "(GMT+10:00) Melbourne", value: "Australia/Melbourne" },
  { name: "(GMT+10:00) Port Moresby", value: "Pacific/Port_Moresby" },
  { name: "(GMT+10:00) Sydney", value: "Australia/Sydney" },
  { name: "(GMT+10:00) Vladivostok", value: "Asia/Vladivostok" },
  { name: "(GMT+11:00) Magadan", value: "Asia/Magadan" },
  { name: "(GMT+11:00) New Caledonia", value: "Pacific/Noumea" },
  { name: "(GMT+11:00) Solomon Is.", value: "Pacific/Guadalcanal" },
  { name: "(GMT+11:00) Srednekolymsk", value: "Asia/Srednekolymsk" },
  { name: "(GMT+12:00) Auckland", value: "Pacific/Auckland" },
  { name: "(GMT+12:00) Fiji", value: "Pacific/Fiji" },
  { name: "(GMT+12:00) Kamchatka", value: "Asia/Kamchatka" },
  { name: "(GMT+12:00) Marshall Is.", value: "Pacific/Majuro" },
  { name: "(GMT+12:00) Wellington", value: "Pacific/Auckland" },
  { name: "(GMT+13:00) Nuku'alofa", value: "Pacific/Tongatapu" },
  { name: "(GMT+13:00) Samoa", value: "Pacific/Apia" },
  { name: "(GMT+13:00) Tokelau Is.", value: "Pacific/Fakaofo" },
]

export const milesightTimezones = [
  { value: "UTC11 Samoa", name: "(UTC-11:00) United States(Samoa)" },
  { value: "UTC10 Cook", name: "(UTC-10:00) Cook Islands(Rarotonga)" },
  { value: "UTC10 Polynesia", name: "(UTC-10:00) Tahiti" },
  { value: "UTC10 USA", name: "(UTC-10:00) United States(Hawaii)" },
  {
    value: "UTC10 USA2",
    name: "(UTC-10:00) United States(Alaska - Aleutian)",
  },
  { value: "UTC9 USA", name: "(UTC-09:00) United States - Alaska Time" },
  { value: "UTC8 CAN", name: "(UTC-08:00) Canada(Vancouver, Whitehorse)" },
  { value: "UTC8 MEX", name: "(UTC-08:00) Mexico(Tijuana, Mexicali)" },
  { value: "UTC8 USA", name: "(UTC-08:00) United States - Pacific Time" },
  { value: "UTC7 CAN", name: "(UTC-07:00) Canada(Edmonton, Calgary)" },
  { value: "UTC7 MEX", name: "(UTC-07:00) Mexico(Mazatlan, Chihuahua)" },
  { value: "UTC7 USA", name: "(UTC-07:00) United States - Mountain Time" },
  { value: "UTC6 CAN", name: "(UTC-06:00) Canada(Winnipeg, Edmonton)" },
  { value: "UTC6 CHL", name: "(UTC-06:00) Chile(Easter Islands)" },
  { value: "UTC6 MEX", name: "(UTC-06:00) Mexico(Mexico City, Acapulco)" },
  { value: "UTC6 USA", name: "(UTC-06:00) United States - Central Time" },
  { value: "UTC5 BHS", name: "(UTC-05:00) Bahamas(Nassau)" },
  { value: "UTC5 BRA", name: "(UTC-05:00) Brazil(Acre, Amazonas)" },
  { value: "UTC5 CAN", name: "(UTC-05:00) Canada(Montreal, Ottawa, Quebec)" },
  { value: "UTC5 CUB", name: "(UTC-05:00) Cuba(Havana)" },
  { value: "UTC5 USA", name: "(UTC-05:00) United States - Eastern Time" },
  { value: "UTC4 VEN", name: "(UTC-04:00) Venezuela(Caracas)" },
  { value: "UTC4 CAN", name: "(UTC-04:00) Canada(Halifax, Saint John)" },
  { value: "UTC4 CHL", name: "(UTC-04:00) Chile(Santiago)" },
  { value: "UTC4 PRY", name: "(UTC-04:00) Paraguay(Asuncion)" },
  { value: "UTC4 TTB", name: "(UTC-04:00) Trinidad&Tobago" },
  { value: "UTC4 BMU", name: "(UTC-04:00) United Kingdom(Bermuda)" },
  { value: "UTC4 FLK", name: "(UTC-04:00) United Kingdom(Falkland Islands)" },
  {
    value: "UTC3:30 CAN",
    name: "(UTC-03:30) Canada(New Foundland - St. Johns)",
  },
  { value: "UTC3 ARG", name: "(UTC-03:00) Argentina(Buenos Aires)" },
  { value: "UTC3 BRA2", name: "(UTC-03:00) Brazil(no DST)" },
  { value: "UTC3 BRA1", name: "(UTC-03:00) Brazil(DST)" },
  { value: "UTC3 GRL", name: "(UTC-03:00) Denmark(Greenland - Nuuk)" },
  { value: "UTC2 BRA", name: "(UTC-02:00) Brazil(no DST)" },
  { value: "UTC1 PRT", name: "(UTC-01:00) Portugal(Azores)" },
  { value: "UTC0 FRO", name: "(UTC+00:00) Denmark(Faroe Islands -Torshavn)" },
  { value: "UTC0 IRL", name: "(UTC+00:00) Ireland(Dublin)" },
  { value: "UTC0 PRT", name: "(UTC+00:00) Portugal(Lisboa, Porto, Funchal)" },
  {
    value: "UTC0 ESP",
    name: "(UTC+00:00) Spain(Canary Islands - Las Palmas)",
  },
  { value: "UTC0 GBR", name: "(UTC+00:00) United Kingdom(London)" },
  { value: "UTC-1 ALB", name: "(UTC+01:00) Albania(Tirane)" },
  { value: "UTC-1 AUT", name: "(UTC+01:00) Austria(Vienna)" },
  { value: "UTC-1 BEL", name: "(UTC+01:00) Belgium(Brussels)" },
  { value: "UTC-1 HRV", name: "(UTC+01:00) Croatia(Zagreb)" },
  { value: "UTC-1 CZE", name: "(UTC+01:00) Czech Republic(Prague)" },
  { value: "UTC-1 DNK", name: "(UTC+01:00) Denmark(Copenhagen)" },
  { value: "UTC-1 FRA", name: "(UTC+01:00) France(Nice, Paris)" },
  { value: "UTC-1 GER", name: "(UTC+01:00) Germany(Berlin)" },
  { value: "UTC-1 HUN", name: "(UTC+01:00) Hungary(Budapest)" },
  { value: "UTC-1 ITA", name: "(UTC+01:00) Italy(Rome)" },
  { value: "UTC-1 LUX", name: "(UTC+01:00) Luxembourg(Luxembourg)" },
  { value: "UTC-1 MAK", name: "(UTC+01:00) Macedonia(Skopje)" },
  { value: "UTC-1 NAM", name: "(UTC+01:00) Namibia(Windhoek)" },
  { value: "UTC-1 NLD", name: "(UTC+01:00) Netherlands(Amsterdam)" },
  { value: "UTC-1 NGR", name: "(UTC+01:00) Nigeria(Lagos)" },
  { value: "UTC-1 NOR", name: "(UTC+01:00) Norway(Oslo)" },
  { value: "UTC-1 POL", name: "(UTC+01:00) Poland(Warsaw)" },
  { value: "UTC-1 YUG", name: "(UTC+01:00) Serbia(Beograd)" },
  { value: "UTC-1 SVK", name: "(UTC+01:00) Slovakia(Bratislava)" },
  { value: "UTC-1 SVN", name: "(UTC+01:00) Slovenia(Ljubljana)" },
  { value: "UTC-1 ESP", name: "(UTC+01:00) Spain(Madrid, Palma)" },
  { value: "UTC-1 SWE", name: "(UTC+01:00) Sweden(Stockholm)" },
  { value: "UTC-1 CHE", name: "(UTC+01:00) Swiss(Bern, Zurich)" },
  { value: "UTC-1 GIB", name: "(UTC+01:00) United Kingdom(Gibraltar)" },
  { value: "UTC-1 WAT", name: "(UTC+01:00) West Africa Time" },
  { value: "UTC-2 BGR", name: "(UTC+02:00) Bulgaria(Sofia)" },
  { value: "UTC-2 CYP", name: "(UTC+02:00) Cyprus(Nicosia)" },
  { value: "UTC-2 CAT", name: "(UTC+02:00) Central Africa Time" },
  { value: "UTC-2 EGY", name: "(UTC+02:00) Egypt(Cairo)" },
  { value: "UTC-2 EST", name: "(UTC+02:00) Estonia(Tallinn)" },
  { value: "UTC-2 FIN", name: "(UTC+02:00) Finland(Helsinki)" },
  { value: "UTC-2 GAZ", name: "(UTC+02:00) Gaza Strip(Gaza)" },
  { value: "UTC-2 GRC", name: "(UTC+02:00) Greece(Athens)" },
  { value: "UTC-2 ISR", name: "(UTC+02:00) Israel(Jerusalem, Tel Aviv)" },
  { value: "UTC-2 LVA", name: "(UTC+02:00) Latvia(Riga)" },
  { value: "UTC-2 LBN", name: "(UTC+02:00) Lebanon(Beirut)" },
  { value: "UTC-2 MDA", name: "(UTC+02:00) Moldova(Chisinau)" },
  { value: "UTC-2 PAL", name: "(UTC+02:00) Palestine(Gaza)" },
  { value: "UTC-2 ROU", name: "(UTC+02:00) Romania(Bucharest)" },
  { value: "UTC-2 RUS", name: "(UTC+02:00) Russia(Kaliningrad)" },
  { value: "UTC-2 SAC", name: "(UTC+02:00) South Africa(Johannesburg)" },
  { value: "UTC-2 SYR", name: "(UTC+02:00) Syria(Damascus)" },
  { value: "UTC-2 UKR", name: "(UTC+02:00) Ukraine(Kyiv, Odessa)" },
  { value: "UTC-3 JOR", name: "(UTC+03:00) Jordan(Amman)" },
  { value: "UTC-3 BLR", name: "(UTC+03:00) Belarus(Minsk)" },
  { value: "UTC-3 EAT", name: "(UTC+03:00) East Africa Time" },
  { value: "UTC-3 IRQ", name: "(UTC+03:00) Iraq(Baghdad)" },
  { value: "UTC-3 AST", name: "(UTC+03:00) Kuwait(Kuwait City)" },
  { value: "UTC-3 QTR", name: "(UTC+03:00) Qatar(Doha)" },
  { value: "UTC-3 RUS", name: "(UTC+03:00) Russia(Moscow)" },
  { value: "UTC-3 KSA", name: "(UTC+03:00) Saudi Arabia(Riyadh)" },
  { value: "UTC-3 TUR", name: "(UTC+03:00) Turkey(Ankara, Istanbul)" },
  { value: "UTC-3:30 IRN", name: "(UTC+03:30) Iran(Tehran)" },
  { value: "UTC-4 ARM", name: "(UTC+04:00) Armenia(Yerevan)" },
  { value: "UTC-4 AZE", name: "(UTC+04:00) Azerbaijan(Baku)" },
  { value: "UTC-4 GEO", name: "(UTC+04:00) Georgia(Tbilisi)" },
  { value: "UTC-4 RUS", name: "(UTC+04:00) Russia(Samara)" },
  { value: "UTC-4 UAE", name: "(UTC+04:00) United Arab Emirates(Dubai)" },
  { value: "UTC-5 KAZ1", name: "(UTC+05:00) Kazakhstan(Aqtau)" },
  { value: "UTC-5 KAZ", name: "(UTC+05:00) Kazakhstan(Aqtobe)" },
  { value: "UTC-5 PAK", name: "(UTC+05:00) Pakistan(Islamabad)" },
  {
    value: "UTC-5 RUS",
    name: "(UTC+05:00) Russia(Chelyabinsk, Yekaterinburg)",
  },
  { value: "UTC-5:30 IND", name: "(UTC+05:30) India(Calcutta)" },
  { value: "UTC-5:45 NPL", name: "(UTC+05:45) Nepal(Kathmandu)" },
  { value: "UTC-6 KAZ", name: "(UTC+06:00) Kazakhstan(Astana, Almaty)" },
  { value: "UTC-6 KGZ", name: "(UTC+06:00) Kyrgyzstan(Bishkek)" },
  { value: "UTC-6 RUS", name: "(UTC+06:00) Russia(Novosibirsk, Omsk)" },
  { value: "UTC-6:30 MMT", name: "(UTC+06:30) Myanmar(Yangon)" },
  { value: "UTC-7 RUS", name: "(UTC+07:00) Russia(Krasnoyarsk)" },
  { value: "UTC-7 THA", name: "(UTC+07:00) Thailand(Bangkok)" },
  { value: "UTC-7 VTN", name: "(UTC+07:00) Vietnam(Ho Chi Minh City)" },
  { value: "UTC-8 AUS", name: "(UTC+08:00) Australia(Perth)" },
  {
    value: "UTC-8 CHN",
    name: "(UTC+08:00) China(Beijing, Hong Kong, Taipei)",
  },
  { value: "UTC-8 SGP", name: "(UTC+08:00) Singapore" },
  { value: "UTC-9 JPN", name: "(UTC+09:00) Japan(Tokyo)" },
  { value: "UTC-9 KOR", name: "(UTC+09:00) Korea(Seoul)" },
  { value: "UTC-9:30 AUS", name: "(UTC+09:30) Australia(Adelaide)" },
  { value: "UTC-9:30 AUS2", name: "(UTC+09:30) Australia(Darwin)" },
  { value: "UTC-10 AUS", name: "(UTC+10:00) Australia(Canberra, Sydney)" },
  { value: "UTC-10 AUS2", name: "(UTC+10:00) Australia(Brisbane)" },
  { value: "UTC-10 AUS3", name: "(UTC+10:00) Australia(Hobart)" },
  { value: "UTC-10 RUS", name: "(UTC+10:00) Russia(Vladivostok)" },
  { value: "UTC-10:30 AUS", name: "(UTC+10:30) Australia(Lord Howe Island)" },
  { value: "UTC-11 NCL", name: "(UTC+11:00) New Caledonia(Noumea)" },
  {
    value: "UTC-12 NZL",
    name: "(UTC+12:00) New Zealand(Wellington, Auckland)",
  },
  { value: "UTC-12 RUS", name: "(UTC+12:00) Russia(Anadyr, Kamchatka)" },
  { value: "UTC-12:45 NZL", name: "(UTC+12:45) New Zealand(Chatham Islands)" },
  { value: "UTC-13 TON", name: "(UTC+13:00) Tonga(Nukualofa)" },
]
