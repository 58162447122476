
export default {
  props: {
    value: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      hours: "",
      minutes: "",
      rawHours: "",
      rawMinutes: "",
      hourError: "",
      minuteError: "",
      isKeyboardMode: false,
      meridiem: true, // true for AM, false for PM
      meridiemOptions: [
        { label: "AM", value: true },
        { label: "PM", value: false },
      ],
      activePicker: null,
      pickerValue: "",
      pickerKey: Math.random(),
    }
  },
  mounted() {
    this.parseInputValue(this.value)
  },
  methods: {
    validateHours(value) {
      if (value === "") return "Hours required"
      const hours = Number(value)
      if (isNaN(hours)) return "Invalid hour"
      if (hours < 0 || hours > 23) return "Hours must be between 0 and 23"

      return ""
    },

    validateMinutes(value) {
      if (value === "") return "Minutes required"
      const minutes = Number(value)
      if (isNaN(minutes)) return "Invalid minute"
      if (minutes < 0 || minutes > 59) return "Minutes must be between 0 and 59"

      return ""
    },

    handleHourBlur() {
      const error = this.validateHours(this.rawHours)
      this.hourError = error

      if (!error) {
        const hours = Number(this.rawHours) % 12 || 12
        this.hours = hours
        if (Number(this.rawHours) > 12) {
          this.meridiem = false
        }
        this.rawHours = String(hours).padStart(2, "0")
        this.updatePickerValue()
      }
    },

    handleMinuteBlur() {
      const error = this.validateMinutes(this.rawMinutes)
      this.minuteError = error

      if (!error) {
        const minutes = Number(this.rawMinutes)
        this.minutes = minutes
        this.rawMinutes = String(minutes).padStart(2, "0")
        this.updatePickerValue()
      }
    },

    parseInputValue(value) {
      if (!value) return
      const [hourStr, minuteStr] = value.split(":")
      let hours = Number(hourStr)
      const minutes = Number(minuteStr)
      this.meridiem = hours < 12
      hours = hours % 12 || 12
      this.hours = hours
      this.minutes = minutes
      this.rawHours = String(hours).padStart(2, "0")
      this.rawMinutes = String(minutes).padStart(2, "0")
      this.pickerValue = value
      this.hourError = ""
      this.minuteError = ""
    },

    updatePickerValue() {
      let hours = this.meridiem ? this.hours % 12 : (this.hours % 12) + 12
      if (this.hours === 12 && this.meridiem) {
        hours = 0
      }
      const formattedTime = `${String(hours).padStart(2, "0")}:${String(
        this.minutes
      ).padStart(2, "0")}`
      this.pickerValue = formattedTime
    },

    handleHourSelect(value) {
      this.hours = value % 12 || 12
      this.rawHours = String(this.hours).padStart(2, "0")
      this.hourError = ""
      this.updatePickerValue()
      this.activePicker = "MINUTE"
      this.$refs.minuteField.focus()
    },

    handleMinuteSelect(value) {
      this.minutes = value
      this.rawMinutes = String(value).padStart(2, "0")
      this.minuteError = ""
      this.updatePickerValue()
    },

    setActivePicker(picker) {
      if (this.activePicker === picker) return
      this.pickerKey = Math.random()
      this.$nextTick(() => {
        this.activePicker = picker
      })
      const ref = picker === "HOUR" ? "hourField" : "minuteField"
      this.$refs[ref].focus()
    },

    toggleInputMode() {
      this.isKeyboardMode = !this.isKeyboardMode
    },

    handleConfirm() {
      // Validate both fields before confirming
      this.handleHourBlur()
      this.handleMinuteBlur()

      if (!this.hourError && !this.minuteError) {
        this.updatePickerValue()
        this.$emit("input", this.pickerValue)
        this.$emit("close")
        this.setActivePicker("HOUR")
      }
    },

    handleCancel() {
      this.parseInputValue(this.value)
      this.$emit("close")
      this.setActivePicker("HOUR")
    },
  },
}
