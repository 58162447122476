export default {
  name: "ResizeTable",
  data() {
    return {
      tableHeight: null,
      tableRef: "tableContainer",
      headerRef: "headerContainer",
      hideDefaultFooter: false,
    }
  },
  mounted() {
    this.$addEventListener("resize", this.onResize)
  },
  methods: {
    onResize() {
      this.tableHeight = null
      let currentHeight =
          this.$refs[this.tableRef]?.$el?.getBoundingClientRect()?.height,
        height = this.$refs[this.headerRef]?.getBoundingClientRect()?.height
      if (currentHeight >= window.innerHeight) {
        height += !this.hideDefaultFooter ? 59 : 25
        this.tableHeight = window.innerHeight - height
      } else {
        this.tableHeight = "auto"
      }
    },
  },
}
